<template>
  <div class="addActivity">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item label="活动名称" prop="activityName">
        <v-input disabled v-model="form.activityName" :width="250"></v-input>
      </el-form-item>
      <el-form-item
        label="活动回顾文本"
        :rules="[
          { required: false, message: '请输入活动回顾文本', trigger: 'blur' },
        ]"
        prop="feedback"
      >
        <v-ueditor
          :maxLength="20000"
          v-model="form.feedback"
          :isShowImg="false"
        />
      </el-form-item>
      <el-form-item
        label="活动回顾图片"
        :rules="[
          {
            required: false,
            message: '请上传活动回顾图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="fbImageUrls"
      >
        <v-upload :imgUrls.sync="form.fbImageUrls" />
      </el-form-item>
      <el-form-item
        label="活动回顾视频"
        :rules="[
          { required: false, message: '请上传活动回顾视频', trigger: 'blur' },
        ]"
        prop="fbVideoUrl"
      >
        <v-videoUpload :limit="1" :videoUrls.sync="form.fbVideoUrl" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { activityDetail, activityFeedbackEdit } from "./api.js";

export default {
  name: "activityFeedbackTennagers",
  data() {
    return {
      communityList: [],
      activityPointSettingList: [],
      submitConfig: {
        queryUrl: activityDetail,
        submitUrl: activityFeedbackEdit,
      },
      form: {
        activityId: null,
        activityName: "",
        feedback: null,
        fbImageUrls: "",
        fbVideoUrl: "",
      },
      communityId: null,
    };
  },
  mounted() {
    const { activityId, communityId } = this.$route.query;
    this.form.communityId = communityId;
    if (activityId !== undefined) {
      this.$refs.formPanel.getData({ activityId, communityId });
    } else {
    }
    this.$setBreadList("活动回顾");
  },
  components: {},
  computed: {},
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
}
</style>
