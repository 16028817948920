//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
//删除创业达人
const deleteTopicURL = "/gateway/hc-neighbour/topic/deleteTopic";
// 心里评估问卷列表
const psychologicalEvaluationListUrl = `/gateway/hc-serve/manageapi/adolescent/psychologicalEvaluationList`;
// 心里评估问卷得分列表
const psychologicalEvaluationScoreListUrl = `/gateway/hc-serve/manageapi/adolescent/psychologicalEvaluationScoreList`;
// 问卷 发布/取消
const questionnairePublishUrl = `/gateway/hc-serve/manageapi/adolescent/questionnairePublish`;
// 问卷删除
const questionnaireDeleteUrl = `/gateway/hc-serve/manageapi/adolescent/questionnaireDelete`;
// 问卷新增
const addQuestionnaireUrl = `/gateway/hc-serve/manageapi/adolescent/addQuestionnaire`;
// 问卷编辑
const compileQuestionnaireUrl = `/gateway/hc-serve/manageapi/adolescent/compileQuestionnaire`;
// 问卷问题删除
const deleteQuestionnaireProblemUrl = `/gateway/hc-serve/manageapi/adolescent/deleteQuestionnaireProblem`;
// 问卷详情
const questionnaireDetailsUrl = `/gateway/hc-serve/manageapi/adolescent/questionnaireDetails`;
//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/manageapi/activity/activityList`;
//获取活动详情
const activityDetail = `/gateway/hc-serve/manageapi/activity/activityDetail`;
//增加|编辑活动
const activityEdit = `/gateway/hc-serve/manageapi/activity/activityEdit`;
//增加|编辑活动反馈
const activityFeedbackEdit = `/gateway/hc-serve/manageapi/activity/activityFeedbackEdit`;
//活动取消
const activityCancel = `/gateway/hc-serve/manageapi/activity/activityCancel`;
//取消活动报名
// const cancelActivityApply = `/gateway/hc-serve/manageapi/activity/cancelActivityApply`;
const cancelActivityApply = `/gateway/hc-serve/manageapi/activity/cancelActivityApplications`;
//手机号查询用户
const getUserDetailURL = `/gateway/blade-user/user/detail`;
// 运营人员替别人活动报名
const getActivityApplyForUserURL =
  "/gateway/hc-serve/manageapi/activity/activityApplyForUser";

//活动审核
const activityAudit = `/gateway/hc-serve/manageapi/activity/activityAudit`;

//获取活动参与人列表
const activityApplicantsList = `/gateway/hc-serve/manageapi/activity/activityApplicantsList`;

//积分规则列表
const activityPointSettingList = `/gateway/hc-serve/manageapi/activity/activityPointSettingList`;

//活动申请列表
const activityApplyList = `/gateway/hc-serve/manageapi/activity/activityApplyList`;

//活动申请详情
const activityApplyInfo = `/gateway/hc-serve/manageapi/activity/activityApplyInfo`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;

//公益捐赠审核
const donateAudit = `/gateway/hc-serve/manageapi/donate/donateAudit`;
//活动参与人导出
const exportApplicantsUrl = `/gateway/hc-serve/manageapi/activity/exportActivityApplicantsListToExcel`;
//场地列表
const areaListUrl = `/gateway/hc-serve/manageapi/activity/reservationPlaceList`;

// 志愿者列表
const getVolunteerListUrl = `/gateway/hc-serve/manageapi/volunteer/volunteerList`;
// 拉黑志愿者
const blacklistVolunteerUrl = `/gateway/hc-serve/manageapi/volunteer/blacklistVolunteer`;
// 删除志愿者
const deleteVolunteerUrl = `/gateway/hc-serve/manageapi/volunteer/deleteVolunteer`;
// 志愿活动签到详情接口
const volunteerSignInList = `/gateway/hc-serve/manageapi/volunteer/volunteerSignInDetails`;
// 编辑积分
const integrateCompileUrl = `/gateway/hc-serve/manageapi/volunteer/integrateCompile`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;
export {
  getTopicListURL,
  addTopicURL,
  updateTopicURL,
  getTopicInfoByIdURL,
  deleteTopicURL,
  psychologicalEvaluationListUrl,
  psychologicalEvaluationScoreListUrl,
  questionnairePublishUrl,
  questionnaireDeleteUrl,
  addQuestionnaireUrl,
  compileQuestionnaireUrl,
  deleteQuestionnaireProblemUrl,
  questionnaireDetailsUrl,
  getActivityListUrl,
  activityDetail,
  activityEdit,
  cancelActivityApply,
  getUserDetailURL,
  getActivityApplyForUserURL,
  getCommunityListUrl,
  activityApplicantsList,
  activityCancel,
  activityPointSettingList,
  activityFeedbackEdit,
  activityAudit,
  activityApplyList,
  activityApplyInfo,
  donateAudit,
  exportApplicantsUrl,
  areaListUrl,
  getVolunteerListUrl,
  blacklistVolunteerUrl,
  deleteVolunteerUrl,
  volunteerSignInList,
  integrateCompileUrl,
  getBuildListURL,
};
